import { DISABLE_CHAT, ENABLE_CHAT } from 'actions/types'
import { NEXT_PUBLIC_MQTT_URL, NEXT_PUBLIC_PARTICIPANT_PASSWORD } from 'lib/config'
import MQTT from 'mqtt'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
// client roles
const PARTICIPANT = 'participant'

// connection states
export const IDLE = 'idle'
export const CONNECTING = 'connecting'
export const CONNREGISTERED = 'connregistered'
export const CONNECTED = 'connected'
export const CONNERROR = 'connerror'

// mqtt message topics
// const CONNECTION_REQUEST = 'connectionRequest'
const CONNECTION_LOST = 'connectionLost'

// const FREE_SLOT = 'freeSlot'
// const REGISTERED = 'registered'
// const RECEIVE_VIDEO_ANSWER = 'receiveVideoAnswer'
// const ICE_CANDIDATE = 'iceCandidate'
// const ICE_SERVERS = 'iceServers'
const GLOBAL_CHAT = 'global'
const LIVESTREAM_CHAT = 'livestream'
const GAME_CHAT = 'game'
const KARAOKE_CHAT = 'karaoke'
const PING = 'ping'
const ADMIN = 'admin'

const CHAT = 'chat'
const NOTIFICATION = 'notification'
// const CONFIG = 'config'

const DISABLECHAT = 'disableChat'
// const ERROR = 'error'
// const STREAM_URL = 'streamUrl'

// const JOIN_ROOM = 'joinRoom'
// const LEAVE_ROOM = 'leaveRoom'
// const CHANGE_VIDEO_DIMENSION = 'changeVideoDimension'

// const NOTIFICATION = 'messages'

const useStore = () => {
  const chatDisabled = useSelector((state) => state.chatDisabled)
  const dispatch = useDispatch()
  const disableChat = () => {
    dispatch({
      type: DISABLE_CHAT,
    })
  }

  const enableChat = () => {
    dispatch({
      type: ENABLE_CHAT,
    })
  }

  return {
    chatDisabled,
    disableChat,
    enableChat,
  }
}
export default function useMqttConnection({ uuid, domain }) {
  // const clientId = uuid
  const CUSTOMER = domain
  const [mqtt, setMqtt] = useState(null)
  const [sessionId] = useState(uuidv4)
  const [globalAnswers, setGlobalAnswers] = useState([])
  const [livestreamAnswers, setLivestreamAnswers] = useState([])
  const [gameAnswers, setGameAnswers] = useState([])
  const [karaokeAnswers, setKaraokeAnswers] = useState([])
  const [connectionState, setConnectionState] = useState(IDLE)
  const [notifications, setNotifications] = useState([])
  const { chatDisabled, enableChat, disableChat } = useStore()

  // method for sending mqtt messages
  const sendMqttMessage = useCallback(
    (topic, payload, options) => {
      // check mqtt client exists
      if (!mqtt) return console.warn('refusing to send mqtt message: mqtt client is undefined')

      // serialize outgoing message as JSON
      try {
        var serializedPayload = JSON.stringify(payload)
      } catch (error) {
        return console.error('refusing to send mqtt message: payload could not be serialized as JSON', error)
      }

      // send message
      if (topic.split('/').slice(-1)[0] !== 'pong') console.debug(`publishing ${topic}`, payload)
      mqtt.publish(topic, serializedPayload, { qos: 2, ...options })
    },
    [mqtt]
  )

  // method for sending direct message to user id
  const sendUserMqttMessage = useCallback(
    (payload, scope) => {
      console.log(`${CUSTOMER}/${CHAT}/${scope}`, payload)
      return sendMqttMessage(`${CUSTOMER}/${CHAT}/${scope}`, payload)
    },
    [sendMqttMessage, CUSTOMER]
  )

  const sendNotification = useCallback(
    (payload) => {
      console.log(`${CUSTOMER}/${CHAT}/${NOTIFICATION}/#`, payload)
      return sendMqttMessage(`${CUSTOMER}/${CHAT}/${NOTIFICATION}/`, payload)
    },
    [sendMqttMessage, CUSTOMER]
  )

  const sendRetainedMessage = useCallback(
    (payload, topic, options) => {
      console.log(`${CUSTOMER}/${CHAT}/${ADMIN}/${topic}`, payload)
      return sendMqttMessage(`${CUSTOMER}/${CHAT}/${ADMIN}/${topic}`, payload, options)
    },
    [sendMqttMessage, CUSTOMER]
  )

  useEffect(() => {
    console.log(globalAnswers)
  }, [globalAnswers])

  // // method for sending direct message to a board of users
  // const sendBoardMqttMessage = useCallback(
  //   (nodeId, payload) => {
  //     console.log(`zalando/node/${nodeId}/messages`, payload)
  //     return sendMqttMessage(`node/${nodeId}/messages`, payload)
  //   },
  //   [sendMqttMessage]
  // )

  // // method for sending direct message to all users
  // const sendBroadcastMqttMessage = useCallback(
  //   (payload) => {
  //     console.log('zalando/participant/broadcast/messages', payload)
  //     return sendMqttMessage('zalando/participant/broadcast/messages', payload)
  //   },
  //   [sendMqttMessage]
  // )

  // Store Answers for Global Chat Messages
  const handleGlobalChatMessage = useCallback(
    (payload) => {
      console.log(globalAnswers)
      setGlobalAnswers([...globalAnswers, payload])
    },
    [globalAnswers, setGlobalAnswers]
  )

  // Store Answers for Livestream Chat Messages
  const handleLivestreamChatMessage = useCallback(
    (payload) => {
      setLivestreamAnswers([...livestreamAnswers, payload])
    },
    [setLivestreamAnswers, livestreamAnswers]
  )

  // Store Answers for Game Chat Messages
  const handleGameChatMessage = useCallback(
    (payload) => {
      setGameAnswers([...gameAnswers, payload])
    },
    [setGameAnswers, gameAnswers]
  )

  // Store Answers for Karaoke Chat Messages
  const handleKaraokeChatMessage = useCallback(
    (payload) => {
      setKaraokeAnswers([...karaokeAnswers, payload])
    },
    [setKaraokeAnswers, karaokeAnswers]
  )

  // Store Answers for Notifications
  const handleNotifications = useCallback(
    (payload) => {
      console.log(payload)

      setNotifications([...notifications, payload])
    },
    [setNotifications, notifications]
  )

  // handle ChatConfiguration

  const handleDisableChat = useCallback(
    ({ payload }) => {
      if (payload) {
        disableChat()
        console.debug('chat disabled')
      } else if (!payload) {
        enableChat()
        console.debug('chat enabled')
      }
    },
    [enableChat, disableChat]
  )

  const handleAdminMessage = useCallback(
    (payload, topic) => {
      console.log('admin message ' + payload)
      const messageType = topic.split('/').slice(0)[3]
      console.log(messageType)

      const messageHandlers = {
        [DISABLECHAT]: handleDisableChat,
      }

      if (messageHandlers[messageType]) return messageHandlers[messageType](payload)
      console.warn('incoming message has invalid type', messageType)
      // setKaraokeAnswers([...karaokeAnswers, payload])
    },
    [handleDisableChat]
  )
  // const handleLivestreamChatMessage = useCallback((payload) => {
  //   setLivestreamAnswers([...livestreamAnswers, payload])
  // })

  // event handler for incoming mqtt messages
  const handleMqttMessage = useCallback(
    (topic, payload) => {
      // get message type
      const messageType = topic.split('/').slice(0)[2]
      // console.debug(messageType)
      // parse payload as JSON
      try {
        payload = JSON.parse(payload)
      } catch (error) {
        return console.error('incoming mqtt payload could not be parsed as JSON', error, payload)
      }
      if (messageType !== PING) console.debug('received', topic, payload)

      // call handler for message type
      const messageHandlers = {
        [GLOBAL_CHAT]: handleGlobalChatMessage,
        [LIVESTREAM_CHAT]: handleLivestreamChatMessage,
        [GAME_CHAT]: handleGameChatMessage,
        [KARAOKE_CHAT]: handleKaraokeChatMessage,
        [ADMIN]: handleAdminMessage,
        [NOTIFICATION]: handleNotifications,
      }

      if (messageHandlers[messageType]) return messageHandlers[messageType](payload, topic)
      console.warn('incoming message has invalid type', messageType)
    },
    [
      handleGlobalChatMessage,
      handleKaraokeChatMessage,
      handleLivestreamChatMessage,
      handleGameChatMessage,
      handleAdminMessage,
      handleNotifications,
    ]
  )
  // method for closing mqtt connection and disposing of webRtcPeer
  // const stop = useCallback(() => {
  //   console.debug('stop() called')

  //   // dispose of mqttClient
  //   setMqtt((mqtt) => {
  //     if (mqtt) {
  //       const force = true
  //       mqtt.end(force)
  //     }
  //     return null
  //   })
  // }, [])

  // create mqtt connection on mount, destroy on unmount

  const connect = useCallback(() => {
    // check we're in a browser
    if (typeof window === 'undefined') return

    setConnectionState(CONNECTING)

    // create mqtt connection
    // TODO: Add operator user credentials
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    const host = window.location.host
    const mqttUrl = NEXT_PUBLIC_MQTT_URL
    console.debug('connecting to mqtt', mqttUrl)
    setMqtt((mqtt) => {
      // force-close existing mqtt connection if it exists
      if (mqtt) {
        console.debug('force-closing existing mqtt connection')
        const force = true
        if (mqtt) mqtt.end(force)
      }

      // create new mqtt connection
      return MQTT.connect(mqttUrl, {
        clientId: sessionId,
        username: 'participant',
        password: NEXT_PUBLIC_PARTICIPANT_PASSWORD,
        // username: 'zalando',
        // password: 'a1d0',
        will: {
          topic: `${CUSTOMER}/chat/admin/${CONNECTION_LOST}`,
          qos: 2,
          payload: JSON.stringify({ sessionId, role: PARTICIPANT }),
        },
      })
    })
  }, [sessionId, CUSTOMER])

  // subscribe to client mqtt topic
  useEffect(() => {
    if (!mqtt) return

    console.debug('subscribing to client mqtt topic')
    mqtt.subscribe(`${CUSTOMER}/${CHAT}/#`)
  }, [sessionId, mqtt, CUSTOMER])

  // register mqtt messages handler
  useEffect(() => {
    if (!mqtt) return

    console.debug('registering mqtt message handler')
    mqtt.on('message', handleMqttMessage)
    return () => mqtt.off('message', handleMqttMessage)
  }, [mqtt, handleMqttMessage])
  return {
    connect,
    sendUserMqttMessage,
    sendNotification,
    sendRetainedMessage,
    connectionState,
    gameAnswers,
    globalAnswers,
    karaokeAnswers,
    livestreamAnswers,
    notifications,
    setNotifications,
  }
}
