import axios from 'axios'
import { NEXT_PUBLIC_BACKEND_URL } from 'lib/config'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

const useUpload = () => {
  const accessToken = useSelector((state) => state.accessToken)
  return { accessToken }
}

export default function useFileUpload() {
  const [selectedFile, setSelectedFile] = useState([])
  const [droppedFile, setDroppedFile] = useState([])
  const [imageURL, setImageURL] = useState(null)
  const [fileChosen, setFileChosen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const { accessToken } = useUpload()

  const sendForm = useCallback(() => {
    return new Promise((resolve, reject) => {
      var file = selectedFile[0]
      var filetype = file.type.split('/')[1]
      // get the Presigend URL before sending the data
      getPresignedURL(filetype).then((url) => {
        setError(false)
        setSuccess(false)
        console.log(file)
        const config = {}
        if (file !== undefined && url) {
          setLoading(true)
          axios
            .put(url.urls.put, file, config)
            .then(async (res) => {
              console.log(res)
              setSelectedFile([])
              setFileChosen(false)
              setImageURL(url.get)
              setLoading(false)

              resolve('https://ik.imagekit.io/pfqtgknzf8w/' + url.assetID + '.' + url.filetype)
              setSuccess(true)
            })
            .catch((err) => {
              setSelectedFile([])
              setImageURL(null)
              setFileChosen(false)
              console.log(err)
              setLoading(false)
              reject(err)
              setError(true)
            })
        }
      })
    })

    //   e.preventDefault()
  }, [selectedFile, setSelectedFile, getPresignedURL, setImageURL, setFileChosen, setLoading, setSuccess, setError])

  const sendDroppedForm = useCallback(
    (e) => {
      return new Promise((resolve, reject) => {
        console.log(accessToken)
        // get the Presigend URL before sending the data
        var file = e[0]
        console.log(file.type)
        var filetype = file.type.split('/')[1]
        getPresignedURL(filetype).then((url) => {
          setError(false)
          setSuccess(false)

          // var file = selectedFile[0]
          console.log(file)
          const config = {}
          if (file !== undefined && url) {
            setLoading(true)
            axios
              .put(url.urls.put, file, config)
              .then(async (res) => {
                console.log(res)
                setSelectedFile([])
                setFileChosen(false)
                setImageURL(url.get)
                setLoading(false)

                resolve('https://ik.imagekit.io/pfqtgknzf8w/' + url.assetID + '.' + url.filetype)
                setSuccess(true)
              })
              .catch((err) => {
                setSelectedFile([])
                setImageURL(null)
                setFileChosen(false)
                console.log(err)
                setLoading(false)
                reject(err)
                setError(true)
              })
          }
        })
      })

      //   e.preventDefault()
    },
    [setSelectedFile, accessToken, getPresignedURL, setImageURL, setFileChosen, setLoading, setSuccess, setError]
  )
  // request Presigned Url from Signer
  const getPresignedURL = useCallback(
    (filetype) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`${NEXT_PUBLIC_BACKEND_URL}/presigned_urls/${filetype}`, {
            headers: { Authorization: 'Bearer ' + accessToken },
          })
          .then((res) => {
            console.log(res.data)
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },
    [accessToken]
  )

  const onChangeHandler = useCallback(
    (e) => {
      if (e.target) {
        setSelectedFile(e.target.files)
        setFileChosen(true)
      }
    },
    [setSelectedFile, setFileChosen]
  )

  const onDropHandler = useCallback(
    (e) => {
      return new Promise((resolve, reject) => {
        if (e.length) {
          console.log(e)
          sendDroppedForm(e)
            .then((res) => {
              setFileChosen(true)
              resolve(res)
            })
            .catch((err) => {
              setFileChosen(false)
              reject(err)
            })
        }
      })
    },
    [sendDroppedForm, setFileChosen]
  )
  return {
    selectedFile,
    imageURL,
    setSelectedFile,
    sendForm,
    onChangeHandler,
    onDropHandler,
    fileChosen,
    setDroppedFile,
    loading,
    success,
    error,
  }
}
