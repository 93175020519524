export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const RESET = 'RESET'

export const TOGGLE_CHAT = 'TOGGLE_CHAT'
export const ENABLE_CHAT = 'ENABLE_CHAT'
export const DISABLE_CHAT = 'DISABLE_CHAT'

export const SET_USERNAME = 'SET_USERNAME'

export const SET_CURRENTCOLOR = 'SET_CURRENTCOLOR'

export const SET_CHATTAB = 'SET_CHATTAB'
export const SET_SECONDARYCHATTAB = 'SET_SECONDARYCHATTAB'

export const SET_CURRENTPAGE = 'SET_CURRENTPAGE'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

export const SET_HOSTNAME = 'SET_HOSTNAME'

export const SET_ACCESSTOKEN = 'SET_ACCESSTOKEN'

export const SET_POINTER = 'SET_POINTER'
